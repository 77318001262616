<template>
    <applyWorkTable 
        ref="workTable" 
        :filterIndex="2" 
        >
    </applyWorkTable>
</template>

<script>
    import applyWorkTable from './layout/applyWorkTable.vue'
    import tableScrollTo from '#/js/mixins/tableScrollTo.js';
    // import floatingListTableTitle from '#/js/config/floatingListTableTitle.json';
    export default {
        name: "FloatingListUnApplyList",
        components: {
            applyWorkTable
        },
        data(){
            return{}
        },
        mixins: [tableScrollTo],
        activated() {
            // this.$refs.workTable.tableTitleInit();
            this.tableScrollTo();
            this.$refs.workTable.resize();
            let _this = this;
            window.onresize = function () {
                _this.$refs.workTable.resize();
            }
        },
    }
</script>

<style scoped>

</style>
